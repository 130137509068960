var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"WEjmKPaGJkx2fCQtoN2bu"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { sentryOptions },
} = getConfig();

if (sentryOptions.dsn) {
  Sentry.init({
    ...sentryOptions,
    initialScope: {
      extra: { isSSR: false },
    },
  });
}
